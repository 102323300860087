// apiService.js
import axios from 'axios';
import { toast } from 'react-toastify';
import {getValidToken} from "./tokenService";

const BASE_URL = process.env.REACT_APP_API_URL;

// Create Axios instance
const apiClient = axios.create({
    baseURL: BASE_URL,
    timeout: 1000,
    headers: {
        'Content-Type': 'application/json',
    }
});

apiClient.interceptors.request.use(
    async (config) => {
        try {
            const token = await getValidToken();
            console.log('token', token);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        } catch (error) {
            if (error.message === 'No token available' || error.message === 'Invalid token format') {
                toast.error(`Auth error`);
                // Handle logout if refresh token is missing or invalid.
            } else {
                toast.error(`Auth error`);
            }
            return Promise.reject(error)

        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            // Server responded with a status other than 200 range
            toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
        } else if (error.request) {
            // Request was made but no response received
            toast.error('Error: No response from server');
        } else {
            // Something happened in setting up the request
            toast.error(`Error: ${error.message}`);
        }
        return Promise.reject(error);
    }
);

// Function to set up interceptors
// const setupInterceptors = (getValidToken) => {
//     apiClient.interceptors.request.use(async (config) => {
//         const token = await getValidToken();
//         console.log('token', token);
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     }, (error) => {
//         return Promise.reject(error);
//     });
//
//     apiClient.interceptors.response.use((response) => {
//         return response;
//     }, (error) => {
//         if (error.response) {
//             // Server responded with a status other than 200 range
//             toast.error(`Error: ${error.response.data.message || error.response.statusText}`);
//         } else if (error.request) {
//             // Request was made but no response received
//             toast.error('Error: No response from server');
//         } else {
//             // Something happened in setting up the request
//             toast.error(`Error: ${error.message}`);
//         }
//         return Promise.reject(error);
//     });
// };

export const apiService = {
    getDocuments() {
        return apiClient.get('/api/v1/document');
    },
    addDocument: async (documentData) => {
        return apiClient.post('/api/v1/document', documentData);
    },
    uploadDocument(documentId, fileData) {
        return apiClient.post(`/api/v1/document/${documentId}/upload`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getDocumentTypes() {
        return apiClient.get('/api/v1/document/types');
    },
    addDocumentType(newTypeName) {
        return apiClient.post('/api/v1/document/types', newTypeName);
    },
// --- TREE --
    getTree() {
        return apiClient.get('/api/v1/tree');
    },

    getTreeNode(nodeId) {
        return apiClient.get(`/api/v1/tree/${nodeId}`);
    },

    addTreeNode(nodeData) {
        return apiClient.post('/api/v1/tree', nodeData);
    },

    updateTreeNode(nodeId, nodeData) {
        return apiClient.put(`/api/v1/tree/${nodeId}`, nodeData);
    },

    deleteTreeNode(nodeId) {
        return apiClient.delete(`/api/v1/tree/${nodeId}`);
    },

    reorderTreeNode(nodeId, reorderData) {
        return apiClient.put(`/api/v1/tree/${nodeId}/reorder`, reorderData);
    }
};
