// src/contexts/DocumentContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { apiService } from '../services/apiService';
import { fetchWithCache } from '../utils/cacheUtils';
import { useLogto } from '@logto/react';

const DocumentContext = createContext({});

export const DocumentProvider = ({ children }) => {
    const { isAuthenticated } = useLogto();
    const [documentTypes, setDocumentTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchDocumentTypes = useCallback(async () => {
        try {
            const data = await fetchWithCache(apiService.getDocumentTypes, 'documentTypes');
            setDocumentTypes(data);
        } catch (error) {
            console.error('Failed to fetch document types (context):', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchDocumentTypes();
        } else {
            setLoading(false);
        }
    }, [isAuthenticated, fetchDocumentTypes]);

    const addDocumentType = async (newTypeName) => {
        try {
            await apiService.addDocumentType(newTypeName);
            await fetchDocumentTypes(); // Обновление списка после добавления
        } catch (error) {
            console.error('Failed to add document type:', error);
        }
    };

    const editDocumentType = async (id, updatedName) => {
        try {
            await apiService.editDocumentType(id, updatedName);
            await fetchDocumentTypes();
        } catch (error) {
            console.error('Failed to edit document type:', error);
        }
    };

    const deleteDocumentType = async (id) => {
        try {
            await apiService.deleteDocumentType(id);
            await fetchDocumentTypes();
        } catch (error) {
            console.error('Failed to delete document type:', error);
        }
    };

    const value = {
        documentTypes,
        addDocumentType,
        editDocumentType,
        deleteDocumentType,
        refreshDocumentTypes: fetchDocumentTypes,
        loading
    };

    return (
        <DocumentContext.Provider value={value}>
            {children}
        </DocumentContext.Provider>
    );
};

export const useDocumentContext = () => useContext(DocumentContext);
