// tokenService.js
import axios from 'axios';
import oidcConfig from "./auth";

const getOidcStorageKey = () => {
    const { appId } = oidcConfig;
    return `logto:${appId}:idToken`;
};

const getRefreshTokenKey = () => {
    const { appId } = oidcConfig;
    return `logto:${appId}:refreshToken`;
};

export const refreshToken = async () => {
    console.log('refresh token');
    const refreshToken = localStorage.getItem(getRefreshTokenKey());
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/oidc/token`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: oidcConfig.appId,
            scope: 'openid'
        });

        const newIdToken = response.data.id_token;
        localStorage.setItem(getOidcStorageKey(), newIdToken);
        return newIdToken;
    } catch (error) {
        console.error('Failed to refresh token', error);
        throw error;
    }
};

// Add a new function to check token validity and refresh if needed
export const getValidToken = async () => {
    console.log('Get valid token');
    const idToken = localStorage.getItem(getOidcStorageKey());
    const refreshTokenKey = getRefreshTokenKey();

    if (!idToken) {
        if (localStorage.getItem(refreshTokenKey)) {
            console.warn('Id Token not found, but refresh token is present. Attempting to refresh.');
            return refreshToken()
        }
        throw new Error('No token available');
    }

    // Basic check - you should use a proper JWT decoding library for production
    // This is a very simplified example
    const tokenParts = idToken.split('.');
    if (tokenParts.length !== 3) {
        console.error('Invalid token format');
        if (localStorage.getItem(refreshTokenKey)) {
            console.warn('Token format invalid, but refresh token is present. Attempting to refresh.');
            return refreshToken()
        }
        throw new Error('Invalid token format');
    }
    const payload = JSON.parse(atob(tokenParts[1]));
    const now = Math.floor(Date.now() / 1000);

    if (payload.exp && payload.exp < now) {
        console.warn('Token expired. Attempting to refresh.');
        return refreshToken();
    }

    return idToken;
};
